import { graphql } from "gatsby"
import React from "react"

import FeaturePointsComponent from "./FeaturePoints"

export const fragment = graphql`
  fragment FeaturePointsFragment on WpPage_Flexlayouts_FlexibleLayouts_FeaturePoints {
    sectionHeading
    features {
    text
    icon {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
          )
        }
      }
    }
    text
    subText
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFFeaturePoints = ({
  sectionHeading,
  features,
  config
}) => (
    <FeaturePointsComponent
      sectionHeading={sectionHeading}
      features={features}
      config={config}
    />
)
