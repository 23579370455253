import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import { Section } from "../../UI/Common"

const FeaturePoints = ({ sectionHeading, features, config }) => {
  const { padding } = config || {}
  return (
    <Section padding={padding} className="bg-gray-100">
      <Container>
        <h2 className="sr-only">{sectionHeading}</h2>
        <Row className="justify-content-center">
          {features.map(item => (
            <Col xs={10} md={6} lg={3} key={uuidv4()}>
              <Row className="my-3 w-auto">
                {item.icon && (
                  <Col>
                    <GatsbyImage
                      imgStyle={{ objectFit: 'contain' }}
                      objectFit="scale-down"
                      alt={item.icon.altText}
                      image={getImage(item.icon.localFile)}
                    />
                  </Col>
                )}
                <Col xs={8}>
                  <div className="d-flex flex-column justify-content-center align-items-start h-100">
                    <div className="font-weight-bolder text-uppercase">
                      {item.text}
                    </div>
                    <div className="text-greyed">{item.subText}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default FeaturePoints
